import React from "react"
import { Link } from "gatsby-theme-material-ui"
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h3>Bio</h3>
    <Typography variant="body1" gutterBottom>Monika’s roots are in Austria. She was born and raised in Salzburg, Austria,
      where generations of her family have lived, including her great, great
      grandfather, Sebastian Stief, whose paintings are found in various historic
      buildings and private collections.
      He served as her inspiration during her formal artistic training at the University of
      Utah. Monika delayed her full-time art practice while raising her family. She is
      deeply connected to Utah, her home of over 40 years and is continually inspired
      by the natural beauty it has to offer. Her abstract paintings thrive to capture the
      essence of the American West by engaging the viewers’ imaginations and
      connecting them with their own recollections of the land and their experience in it.
      Monika’s paintings can be found at the SLC International Airport and several
      private and corporate collections in Utah, Arizona, New York, California, the
      Midwest, and Canada.
    </Typography>
    <p></p>
    <h3>Artist Statement</h3>
    <Typography variant="body1" gutterBottom>
      <Typography variant="body2" gutterBottom>“The law of chance can be experienced only in a total surrender to the unconscious.” - Jean Arp</Typography><p>&nbsp;</p>
      Beginning with the Dadaist movement the element of chance as a way to access
      the subconscious has been an important theme in 20th Century art and was
      embraced by Surrealist and Abstract Expressionist artists.
      My work continues in this tradition. I am inspired by the beauty and chaos of
      natural processes and nature in motion. Our world is rich with structure, color
      and texture: mineral patterns in stone, the swirls of a river eddy, the complexity
      and bounty of marine life.
      I employ innovative media, chemical processes and movement to capture the
      poetry, sublimity and transcendence inherent in nature. My work has a deliberate
      meditative aspect intended to take the viewer on an individual journey of
      discovery of what is both familiar and yet unknown.
    </Typography>
    <p>&nbsp;</p>
    <Link to="/">Go back to the homepage</Link>
    <p>&nbsp;</p>
  </Layout>
);

export default AboutPage